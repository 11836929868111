/* eslint-disable */

// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

/****
  *************
  *************
    Lazy Loading Images, Videos, and iFrames
    for some reason this doesn't work when placed in common.js
  *************
  *************

  * For <img> tags
  *****************

  Add class .lazy and change src to data-src
  <img class="lazy" data-src="/media/homenew.jpg"/>

  * For inline CSS background images
  ************************************

  Add class .lazy to the div
  Remove any style="background-image:url('');background-size:cover;" etc
  Add custom data attributes:

  <div class="lazy" data-bgimage-medium="<?php echo $mobileBgImageURL; ?>" data-bgimage-full="<?php echo $bgImageURL; ?>" <?php if ( $bgGradientOverlay ) : ?> data-bggradient-overlay="<?php echo $bgGradientOverlay; ?>"<?php endif; ?> data-bgsize="cover" data-bgposition="center center" data-bgrepeat="no-repeat">

  * For CSS background images defined
  * in CSS files
  ************************************

  Add class .lazy background to div:

  <div class="my-bg-div lazy-background">

  Remove background image from CSS class and add it nested under a .visible class:

  .my-bg-div {
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    &.visible {
      background-image('../../images/myimg.jpg');
    }
  }

  * For iFrames
  ************************************

  Add class .lazy to iframe
  Change src to data-src

  <iframe class="lazy" data-src="https://snazzymaps.com/embed/289457" style="border:none;"></iframe>

  * For Videos
  ************************************

  Add class .lazy to <video> tag
  Change src to data-src in <source> tag

  <video playsinline autoplay muted loop class="lazy d-none d-xl-block">
    <source data-src="/my-video.mp4" type="video/mp4">
  </video>

  * For Sliders
  ************************************

  Make sure Splide Intersection is imported at the top of common.js

  import { Intersection } from '@splidejs/splide-extension-intersection';

  Add the appropriate lazy classes the way you normally would.

  Update your slider syntax to use the Intersection Observer (this example uses a slider with <img data-src="/myimage.jpg"/> tags):

  var logoSliders = document.getElementsByClassName('rpb-logo-slider');
  for (var logoCount = 0; logoCount < logoSliders.length; logoCount++) {
      // mount a new slider with the Intersection observer
      var logoSplide = new Splide(logoSliders[logoCount]).mount({ Intersection });
      // on intersection, set the data-src of the slider img to the src
      logoSplide.on( 'intersection:in', function ( entry ) {
          $(entry.target).find('.logo-banner-img').each(function() {
              var logoAttr = $(this).attr('data-src');
              $(this).attr('src',logoAttr);
          });
      } );
  }
*****/

document.addEventListener('DOMContentLoaded', function () {
    function applyBgImageBasedOnScreenWidth() {
        document.querySelectorAll('.lazy').forEach(element => {
            const tagName = element.tagName.toLowerCase();
            const screenWidth = window.innerWidth;

            // Handling img with srcset
            if (tagName === 'img' && element.dataset.srcset) {
                element.srcset = element.dataset.srcset;
                if (element.dataset.src) {
                    element.src = element.dataset.src; // Fallback src
                }
            }
            // Handling video tags
            else if (tagName === 'video' && element.children) {
                Array.from(element.children).forEach(source => {
                    if (source.tagName.toLowerCase() === 'source' && source.dataset.src) {
                        source.src = source.dataset.src;
                    }
                });
                element.load();
            }
            // Handling iframe tags
            else if (tagName === 'iframe' && element.dataset.src) {
                element.src = element.dataset.src;
            }
            // Handling background images
            else if (element.classList.contains('lazy')) {
                const bgImageMedium = element.getAttribute('data-bgimage-medium');
                console.log(bgImageMedium);
                const bgImageFull = element.getAttribute('data-bgimage-full');
                
                if (bgImageMedium || bgImageFull) {
                    let bgImage = screenWidth < 768 && bgImageMedium ? `url(${bgImageMedium})` : `url(${bgImageFull})`;
                    const bgGradientOverlay = element.getAttribute('data-bggradient-overlay');
                    console.log(bgGradientOverlay);
                    if (bgGradientOverlay) {
                        const overlayFormatted = bgGradientOverlay.includes('gradient') ? bgGradientOverlay : `linear-gradient(${bgGradientOverlay}, ${bgGradientOverlay})`;
                        bgImage = `${overlayFormatted}, ${bgImage}`;
                    }
                    
                    element.style.backgroundImage = bgImage;
                    element.style.backgroundSize = element.getAttribute('data-bgsize') || '';
                    element.style.backgroundPosition = element.getAttribute('data-bgposition') || '';
                    element.style.backgroundRepeat = element.getAttribute('data-bgrepeat') || '';
                }
            }

            // Common cleanup for all lazy-loaded elements
            if (element.classList.contains('lazy')) {
                element.classList.remove('lazy');
            }
        });
    }

    if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    applyBgImageBasedOnScreenWidth(); // Apply styles to newly visible elements
                    observer.unobserve(entry.target);
                }
            });
        }, { rootMargin: '0px', threshold: 0.1 });

        document.querySelectorAll('.lazy, .lazy-bg').forEach(el => observer.observe(el));
    }

    window.addEventListener('resize', applyBgImageBasedOnScreenWidth); // Reapply on resize
    applyBgImageBasedOnScreenWidth(); // Initial application
});

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import singleLocation from './routes/location';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    // Single location
    singleLocation,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
